import { get, post } from '../../../../api/index';
//我的信息模块
export const mycourseUser = params => get(`/api/mycourse/v1/user`, params); // 我的信息模块

// 学习任务
export const taskList = params => get(`/api/studytask/v1/study_get_list`, params); // 学习任务列表
export const taskAdd = params => post(`/api/studytask/v1/study_add`, params); // 学习任务新建
export const taskEdit = params => post(`/api/studytask/v1/study_edit`, params); // 学习任务编辑
export const taskDel = params => post(`/api/studytask/v1/study_delete`, params); // 学习任务删除
export const taskUpdate = params => post(`/api/studytask/v1/study_update_status`, params); // 学习任务完成
// 读书笔记
export const noteList = params => get(`/api/notes/v1/noteslist`, params); // 读书笔记列表
export const noteAdd = params => post(`/api/notes/v1/notesadd`, params); // 读书笔记新建
export const noteEdit = params => post(`/api/notes/v1/notesedit`, params); // 读书笔记编辑
export const noteDel = params => get(`/api/notes/v1/notesdel`, params); // 读书笔记删除
//直播日历
export const liveCalendar = params => get(`/api/calendar/v1/live_calendar`, params); // 直播日历、课表
//教学计划
export const planList = params => get(`/api/mycourse/v1/project`, params); // 教学计划列表
export const planCourseList = params => get(`/api/mycourse/v1/project/detail`, params); // 单个教学计划下的课程列表

//学习记录
export const recordList = params => get(`/api/watchtime/v1/recordlist`, params); // 学习记录
export const recordPageList = params => get(`/api/watchtime/v1/recordpage`, params); // 学习记录-更多
export const recordClear = params => get(`/api/watchtime/v1/recordclear`, params); // 学习记录清除

//我的课程
export const mycourseKDList = params => get(`/api/mycourse/v1/list`, params); // 我的课单
export const myCourseKDAdd = params => post(`/api/mycourse/v1/list/add`, params); // 添加课单
export const myCourseKDEdit = params => post(`/api/mycourse/v1/list/edit`, params); // 编辑课单
export const myCourseKDDel = params => post(`/api/mycourse/v1/list/del`, params); // 删除课单
export const myCourseKDDetail = params => get(`/api/mycourse/v1/list/detail`, params); // 课单下课程列表
export const myCourseKDRemove = params => post(`/api/mycourse/v1/list/remove`, params); // 移出课单
export const myCourseKDTop = params => post(`/api/mycourse/v1/list/top`, params); // 置顶操作


//小米考试
export const xiaomilist = params => get(`/api/newpaper/v1/xiaomi/list`, params);//小米我的考试列表
export const xiaomiresult = params => get(`/api/newpaper/v1/xiaomi/result`, params);//小米我的考试结果

// 我的证书
export const certificateList = params => get(`/api/mycourse/v1/certificate/list`, params);//小米我的考试列表
